<div class="main-container">
  <ng-container *ngIf="!loading; else loadingTpl">
    <header class="details-header">
      <div class="header-left with-shadow">
        <div class="details-header-order">
          <h2 class="header-title">
            {{
              'order-details.header.client-order'
                | translate: { orderId: order.orderExternalIdentifier }
            }}
          </h2>
          <p>
            {{
              (civility.includes(order.orderCustomerJsonData.gender)
                ? 'common.civility.' + order.orderCustomerJsonData.gender
                : 'common.civility.undefined'
              ) | translate
            }}
            {{ order.orderCustomerJsonData.first_name }} {{ order.orderCustomerJsonData.last_name }}
          </p>
          <p class="order-creation-date">
            {{ 'common.creation-date:' | translate }} {{ order.orderPlacedOn | date }}
            {{ 'common.at' | translate }}
            {{ order.orderPlacedOn | time }}
          </p>
        </div>
        <!-- barcode -->
        <div class="barcode-container">
          <svg #barcode class="barcode"></svg>
        </div>
      </div>
      <div class="header-right with-shadow">
        <div class="order-details-header-container">
          <p>
            {{ 'order-details.header.to-deliver:' | translate }}
            {{ order.orderDeadlinePreparation | date }}
            {{ 'common.at' | translate }}
            {{ order.orderDeadlinePreparation | time }}
          </p>
          <p>
            {{
              order.target === Target.VAP
                ? ('order-details.header.collection-point' | translate)
                : ('order-details.header.replenishment-point' | translate)
            }}: {{ order.shopCode }}
          </p>
          <p>
            <button type="button" class="link" (click)="specifyStorage()">
              <ng-container *ngIf="order.orderStorageLocation"
                >Storage box: {{ order.orderStorageLocation }}</ng-container
              >
              <ng-container *ngIf="!order.orderStorageLocation">
                {{ 'order-details.header.specify-storage' | translate }}
              </ng-container>
            </button>
          </p>
        </div>
        <div [class]="'order-cell_status ' + ('order-status-' + order?.orderStatus | slugify)">
          <span class="customer-badge">{{ 'order-status.' + order.orderStatus | translate }}</span>
          <button
            class="c-p signature__button--white"
            *ngIf="viewSignature"
            (click)="signatureDialog()"
            type="button"
          >
            <i class="pi pi-info-circle"></i>
          </button>
        </div>
      </div>
    </header>

    <button
      class="pi pi-refresh refresh-button"
      (click)="refresh()"
      title="{{ 'order-details.refresh-cta' | translate }}"
    ></button>

    <!-- dev mode: use [activeIndex]="2" to force active tab -->

    <p-tabView class="tab-container">
      <p-tabPanel [header]="'order-details.tabs.prepare' | translate">
        <p-table
          [loading]="loadingTable"
          [value]="order.orderlinesJsonData"
          scrollHeight="440px"
          responsiveLayout="scroll"
          class="order-details-tab"
        >
          <ng-template pTemplate="header">
            <tr>
              <th colspan="2">{{ 'order-details.columns.products' | translate }}</th>
              <th>{{ 'order-details.columns.qty' | translate }}</th>
              <th>{{ 'order-details.columns.price' | translate }}</th>
              <th>{{ 'order-details.columns.stock-merchant' | translate }}</th>
              <th *ngIf="replenishable">{{ 'order-details.columns.stock-wone' | translate }}</th>
              <th *ngIf="replenishable && viewPick" class="ro-column">
                {{ 'order-details.columns.replenishment-order' | translate }}
              </th>
              <th *ngIf="displayPickOLHeader()"></th>
              <th *ngIf="displayPreparedQtyHeader()">
                {{ 'order-details.columns.prepared-qty' | translate }}
              </th>
              <th *ngIf="viewPick"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-orderline let-i="rowIndex">
            <tr [class.orderline-cancelled]="orderline.statusCode === OrderlineStatus.CANCELLED">
              <td>
                <!-- image -->
                <img
                  *ngIf="orderline.productInfo?.images?.length"
                  width=" 80"
                  [src]="orderline.productInfo?.images[0]"
                  alt=""
                />
              </td>
              <!-- description -->
              <td>
                <!-- brand -->
                <p *ngIf="orderline.productInfo?.manufacturer.labels" class="orderline-brand">
                  <strong>{{ orderline.productInfo?.manufacturer.labels | i18nLabel }}</strong>
                </p>
                <!-- description -->
                <p
                  *ngIf="orderline.productInfo?.name.labels"
                  (click)="displaySkuPopin(orderline)"
                  class="orderline-description"
                >
                  {{
                    (orderline.productInfo?.name.labels
                      ? (orderline.productInfo.name.labels | i18nLabel)
                      : orderline.productName
                    ) | truncate: 40
                  }}
                  <i class="pi pi-info-circle"></i>
                </p>
                <!-- variations -->
                <p *ngFor="let variation of getVariations(orderline.productInfo)">
                  {{ variation.value.labels | i18nLabel }}
                </p>
                <p class="orderline-sku">
                  {{ 'order-details.prepare.sku:' | translate }} {{ orderline.productSku }}
                </p>
                <p class="orderline-ean">
                  {{ 'order-details.prepare.ean:' | translate }} {{ orderline.productInfo?.ean }}
                </p>
              </td>
              <!-- quantity -->
              <td class="order-details-qty">{{ orderline.productOrderedQty }}</td>
              <!-- price -->
              <td>
                <p>
                  {{
                    orderline.productUnitPriceVATIncludedDiscountIncluded
                      | price: order.orderCurrency
                  }}
                </p>
                <p class="amount-before-discount">
                  <span *ngIf="orderline.productUnitPriceVATIncludedDiscountExcluded !== null">
                    <span *ngIf="orderline.productUnitPriceVATIncludedDiscountExcluded > 0">
                      {{
                        orderline.productUnitPriceVATIncludedDiscountExcluded
                          | price: order.orderCurrency
                      }}
                    </span>
                    <i
                      *ngIf="orderline.promotions[0]"
                      class="pi pi-info-circle promotion"
                      (click)="showDisplayPromotion(orderline.promotions)"
                    ></i>
                  </span>
                </p>
              </td>
              <!-- stock merchant -->
              <td>
                <ng-container *ngIf="orderline.stocks?.length">
                  <div class="stocks">
                    <span *ngFor="let stock of orderline.stocks"
                      >{{ stock.organizationCode }}: {{ stock.stock }}</span
                    >
                  </div>
                </ng-container>
              </td>
              <!-- stock WONE -->
              <td *ngIf="replenishable">
                <ng-container *ngIf="stocksWone[orderline.orderlineId] !== undefined">
                  {{ stocksWone[orderline.orderlineId] }}
                </ng-container>
              </td>
              <!-- RO -->
              <td *ngIf="replenishable && viewPick" class="ta-r">
                <button
                  *ngIf="
                    pickMode &&
                    getRRStatus(orderline) === 'none' &&
                    orderline.statusCode !== OrderlineStatus.CANCELLED
                  "
                  pButton
                  type="button"
                  class="ro-cta p-button-text link"
                  [label]="'order-details.prepare.ro-cta' | translate"
                  appPreventDoubleClick
                  (debounceClick)="replenish(orderline)"
                ></button>
                <ng-container *ngIf="getRRStatus(orderline) === 'created'">
                  <p>
                    <a
                      *ngIf="
                        !orderline.replenishmentRequest.replenishmentOrder?.replenishmentOrderId
                      "
                      routerLink="/ro"
                      class="link"
                      >{{ 'order-details.prepare.ro-in-progress' | translate }}</a
                    >
                    <a
                      *ngIf="
                        orderline.replenishmentRequest.replenishmentOrder?.replenishmentOrderId
                      "
                      [routerLink]="[
                        '/ro',
                        orderline.replenishmentRequest.replenishmentOrder?.replenishmentOrderId
                      ]"
                      class="link"
                      >{{ 'order-details.prepare.ro-in-progress' | translate }}</a
                    >
                  </p>
                </ng-container>
                <ng-container
                  *ngIf="orderline.replenishmentRequest && getRRStatus(orderline) === 'sent'"
                >
                  <p>
                    <a [routerLink]="['/ol', orderline.replenishmentRequest.replenishmentOrderId]"
                      ><strong
                        >#{{
                          orderline.replenishmentRequest.replenishmentOrder.replenishmentSAPCode
                        }}</strong
                      ></a
                    >
                  </p>
                  <p>{{ orderline.replenishmentRequest.createdOn | time }}</p>
                  <p>{{ orderline.replenishmentRequest.createdOn | date }}</p>
                </ng-container>
              </td>
              <!-- pick OL -->
              <td
                *ngIf="
                  viewPick &&
                  pickMode &&
                  [
                    OrderlineStatus.NEW,
                    OrderlineStatus.WAITING_FOR_RESTOCK,
                    OrderlineStatus.CANCELLED
                  ].includes(orderline.statusCode)
                "
              >
                <p-dropdown
                  *ngIf="
                    [OrderlineStatus.NEW, OrderlineStatus.WAITING_FOR_RESTOCK].includes(
                      orderline.statusCode
                    ) && orderline.stocks?.length > 1
                  "
                  placeholder="{{ 'order-details.select' | translate }}"
                  [options]="orderline.stocks"
                  optionLabel="organizationCode"
                  [autoDisplayFirst]="false"
                  (onChange)="initPreparedQty(orderline)"
                  inputId="{{ 'stocksSelectionDropdown-' + orderline.orderlineId }}"
                  [(ngModel)]="stocksSelection[orderline.orderlineId]"
                >
                </p-dropdown>
              </td>
              <!-- prepared quantity -->
              <td *ngIf="displayPreparedQtyRow(orderline)" class="order-details-qty">
                <p-dropdown
                  *ngIf="
                    pickMode &&
                      [OrderlineStatus.NEW, OrderlineStatus.WAITING_FOR_RESTOCK].includes(
                        orderline.statusCode
                      );
                    else preparedQuantity
                  "
                  [autoDisplayFirst]="false"
                  [options]="quantityToArray(orderline.productOrderedQty, orderline.orderlineId)"
                  inputId="{{ 'quantitySelectionDropdown-' + orderline.orderlineId }}"
                  [(ngModel)]="quantitySelection[orderline.orderlineId]"
                >
                </p-dropdown>
                <ng-template #preparedQuantity>
                  {{ orderline.productFulfilledQty }}
                </ng-template>
              </td>
              <!-- pick CTA -->
              <td *ngIf="viewPick" class="ta-r pick-column">
                <ng-container
                  *ngIf="
                    pickMode &&
                    [OrderlineStatus.NEW, OrderlineStatus.WAITING_FOR_RESTOCK].includes(
                      orderline.statusCode
                    )
                  "
                >
                  <container-element *ngIf="orderline.stocks" [ngSwitch]="orderline.stocks?.length">
                    <ng-container *ngSwitchCase="0">
                      <p class="ws-nw no-stock">
                        <i class="pi pi-times"></i>
                        {{ 'order-details.prepare.no-stock' | translate }}
                      </p>
                      <!-- if no stock, pick button should still be available (ER-670) -->
                      <p-button
                        appPreventDoubleClick
                        (debounceClick)="pickOL(orderline)"
                        class="ws-nw"
                      >
                        {{ 'order-details.prepare.pick' | translate }} {{ order.shopCode }}
                      </p-button>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                      <p-button
                        appPreventDoubleClick
                        (debounceClick)="pickOL(orderline, orderline.stocks[0].organizationId)"
                        class="ws-nw"
                      >
                        {{ 'order-details.prepare.pick:' | translate }}
                        {{ orderline.stocks[0].organizationCode }}
                      </p-button>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <p-button
                        *ngIf="stocksSelection[orderline.orderlineId].organizationId"
                        appPreventDoubleClick
                        (debounceClick)="
                          pickOL(orderline, stocksSelection[orderline.orderlineId].organizationId)
                        "
                        class="ws-nw"
                      >
                        {{ 'order-details.prepare.pick:' | translate }}
                        {{ stocksSelection[orderline.orderlineId].organizationCode }}
                      </p-button>
                    </ng-container>
                  </container-element>
                </ng-container>
                <ng-container
                  *ngIf="
                    orderline.stocks &&
                    [
                      OrderlineStatus.FULFILLED,
                      OrderlineStatus.PARTIAL,
                      OrderlineStatus.DELIVERED,
                      OrderlineStatus.RETURNED
                    ].includes(orderline.statusCode)
                  "
                >
                  <p class="ws-nw">
                    <i class="pi pi-check"></i>
                    {{ stocksSelection[orderline.orderlineId].organizationCode }} :
                    {{ orderline.productFulfilledQty }}
                  </p>
                  <button
                    *ngIf="pickMode"
                    pButton
                    appPreventDoubleClick
                    (debounceClick)="resetOL(orderline)"
                    type="button"
                    [label]="'order-details.prepare.unpick' | translate"
                    class="p-button-text link"
                  ></button>
                </ng-container>
                <ng-container *ngIf="orderline.statusCode === OrderlineStatus.CANCELLED">
                  <p class="ws-nw">
                    <i class="pi pi-times"></i>
                    {{ 'order-details.prepare.cancelled' | translate }}
                  </p>
                </ng-container>
                <ng-container *ngIf="[OrderlineStatus.ERROR].includes(orderline.statusCode)">
                  <p class="error">{{ 'error' | translate }}</p>
                </ng-container>
                <!-- <p class="ws-nw" style="background-color: lightblue;">DEBUG: {{orderline.statusCode}}</p> -->

                <!-- cancel / trash / delete -->
                <br />
                <button
                  *ngIf="
                    pickMode &&
                    ![
                      OrderlineStatus.CANCELLED,
                      OrderlineStatus.FULFILLED,
                      OrderlineStatus.PARTIAL
                    ].includes(orderline.statusCode)
                  "
                  pButton
                  type="button"
                  class="p-button-text orderline-delete-button link"
                  [label]="'order-details.prepare.ol-delete' | translate"
                  appPreventDoubleClick
                  (debounceClick)="cancelOL(orderline)"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td colspan="3">
                {{ 'order-details.footer.subtotal' | translate }}
              </td>
              <td *ngIf="displayPreparedQtyHeader()" colspan="3"></td>
              <td [colSpan]="viewPick ? '6' : '3'" class="ta-r">
                {{ order.orderSubtotal | price: order.orderCurrency }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                {{ 'order-details.footer.discount' | translate }}
              </td>
              <td *ngIf="displayPreparedQtyHeader()" colspan="3"></td>
              <td [colSpan]="viewPick ? '6' : '3'" class="ta-r">
                {{ order.orderDiscountTotal | price: order.orderCurrency }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                {{ 'order-details.footer.tarif' | translate }}
              </td>
              <td *ngIf="displayPreparedQtyHeader()" colspan="3"></td>
              <td [colSpan]="viewPick ? '6' : '3'" class="ta-r">
                {{ 'common.tarification.' + order.orderPricelistApplied | translate }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                {{ 'order-details.footer.total' | translate }}
              </td>
              <td *ngIf="displayPreparedQtyHeader()" colspan="3"></td>
              <td [colSpan]="viewPick ? '6' : '3'" class="ta-r">
                {{ order.orderAmount | price: order.orderCurrency }}
              </td>
            </tr>
            <!-- cancel button -->
            <tr>
              <td [colSpan]="displayPreparedQtyHeader() ? 12 : 9" class="cancel-button-container">
                <button
                  #orderCancelButton
                  *ngIf="order && order.state.canCancel && pickMode"
                  pButton
                  type="button"
                  class="cancel-button p-button-outlined"
                  (click)="displayCancelDialog()"
                >
                  {{ 'ctas.cancel-order' | translate }}
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
      <p-tabPanel [header]="'order-details.tabs.pickup-point' | translate" class="tab-pickup-point">
        <app-order-pickup-point [order]="order"></app-order-pickup-point>
      </p-tabPanel>
      <p-tabPanel [header]="'order-details.tabs.description' | translate" class="tab-description">
        <app-order-description
          [order]="order"
          [boardingPass]="boardingPass"
        ></app-order-description>
      </p-tabPanel>
      <p-tabPanel [header]="'order-details.tabs.history' | translate" class="">
        <div class="order-details-history-search">
          <form
            [formGroup]="logMessageForm"
            (ngSubmit)="addLogMessage()"
            class="add-log-message-form"
          >
            <input
              formControlName="logMessage"
              placeholder="{{ 'order-details.history.add-message' | translate }}"
            />
            <button pButton type="submit" appPreventDoubleClick (debounceClick)="addLogMessage()">
              {{ 'order-details.history.add-message-cta' | translate }}
            </button>
          </form>
        </div>
        <div class="orders-logs-container">
          <p-table
            [value]="order.orderJsonLogs"
            sortField="when"
            [sortOrder]="-1"
            [scrollable]="true"
            scrollHeight="flex"
          >
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="when">
                  {{ 'order-details.history.date' | translate }}
                  <p-sortIcon field="when"></p-sortIcon>
                </th>
                <th>{{ 'order-details.history.sm-login' | translate }}</th>
                <th>{{ 'order-details.history.log-message' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-log>
              <tr>
                <td>
                  <p>
                    {{ log.when | time }}
                    <span>
                      {{ log.when | date }}
                    </span>
                  </p>
                </td>
                <td>{{ log.who }}</td>
                <td class="log-message">{{ log.what }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </ng-container>

  <footer class="details-footer">
    <button pButton type="button" class="p-button-outlined button-quit" (click)="back()">
      {{ 'ctas.quit' | translate }}
    </button>
    <button
      *ngIf="order && (pickMode || !order.state.canPick) && order?.adminCta?.[0]?.details?.label && order?.adminCta?.[0]?.details?.action && isSuperAdmin"
      pButton
      appPreventDoubleClick
      (debounceClick)="adminCta0Action()"
      class="cta-main button-finish"
    >
      {{order?.adminCta?.[0]?.details?.label || ''
      |translate}}
    </button>
    <button
      *ngIf="order && (pickMode || !order.state.canPick) && order?.adminCta?.[1]?.details?.label && order?.adminCta?.[1]?.details?.action && isSuperAdmin"
      pButton
      appPreventDoubleClick
      (debounceClick)="adminCta1Action()"
      class="cta-main button-finish"
    >
      {{order?.adminCta?.[1]?.details?.label || ''
      |translate}}
    </button>

    <!-- deliver button-->

    <button
      *ngIf="
        order &&
        (pickMode || !order.state.canPick) &&
        order?.cta?.details?.label &&
        order?.cta?.details?.action
      "
      pButton
      appPreventDoubleClick
      (debounceClick)="ctaAction()"
      class="cta-main button-finish"
    >
      {{ order?.cta?.details?.label || '' | translate }}
    </button>

    <p
      *ngIf="order && order?.cta?.details?.label && !order?.cta?.details?.action"
      class="cta-main cta-main_label"
    >
      {{ order?.cta?.details?.label || '' | translate }}
    </p>
    <button
      *ngIf="order && !pickMode && order.state.canPick && order?.cta?.details?.viewLabel"
      pButton
      appPreventDoubleClick
      (debounceClick)="prepare()"
      class="cta-main button-finish"
    >
      {{ order?.cta?.details?.viewLabel || '' | translate }}
    </button>
  </footer>
</div>
<ng-template #loadingTpl>
  <app-full-page-spinner></app-full-page-spinner>
</ng-template>
